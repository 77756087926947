import {Injectable, OnDestroy} from '@angular/core';
import {AuthService} from '../services/auth.service';

@Injectable({
    providedIn: 'root',
})
export class AuthManager implements OnDestroy {


    constructor(private authService: AuthService) {
    }

    get user() {
        return this.authService.claims;
    }

    logout() {
        this.authService.logout();
    }

    ngOnDestroy() {
    }
}
