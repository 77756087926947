import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LayoutService } from '../../../../../core';
import { AuthManager } from '../../../../../core/managers/auth.manager';

@Component({
  selector: 'app-user-offcanvas',
  templateUrl: './user-offcanvas.component.html',
  styleUrls: ['./user-offcanvas.component.scss'],
})
export class UserOffcanvasComponent implements OnInit {
  extrasUserOffcanvasDirection = 'offcanvas-right';

  constructor(
    private layout: LayoutService,
    public authManager: AuthManager,
    private router: Router) { }

  ngOnInit(): void {
    this.extrasUserOffcanvasDirection = `offcanvas-${this.layout.getProp(
      'extras.user.offcanvas.direction'
    )}`;
  }
  // changePasswor(){
  //   this.router.navigate(['/account/changePassword'])
  // }
}
