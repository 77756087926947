<!--begin::Container-->
<div [ngClass]="footerContainerCSSClasses"
  class="d-flex flex-column flex-md-row align-items-center justify-content-between">
  <!--begin::Copyright-->
  <div class="text-dark ml-auto">
    <span class="text-muted font-weight-bold mr-2">{{ currentYear }} &copy;</span>
    <a href="https://itisco.com.sa/home/default.aspx" target="_blank" class="text-dark-75 text-hover-primary">
      ItIs
    </a>
  </div>
  <!--end::Copyright-->
</div>
<!--end::Container-->