import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { PermissionsManager } from '../managers/permissions.manager';

@Injectable({
    providedIn: 'root'
})
export class ContentGuard implements CanActivate {
    permissions = [];

    /**
     *
     * @param router to Redirect if not allowed
     * @param permissionsManager to Get all permissions
     */
    constructor(
        private router: Router,
        public permissionsManager: PermissionsManager
    ) {
    }

    /**
     * Allow access to specific roles only according to their permissions
     */
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const requiredPermission = route.data.permission;
        return this.permissionsManager.isPermissionGranted(requiredPermission);
    }
}
