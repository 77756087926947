import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ClipboardModule } from 'ngx-clipboard';
import { TranslateModule } from '@ngx-translate/core';
import { InlineSVGModule } from 'ng-inline-svg';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SplashScreenModule } from './partials/layout/splash-screen/splash-screen.module';
import { OAuthModule, OAuthStorage } from 'angular-oauth2-oidc';
import { ApiInterceptor } from './core/interceptors/api.interceptor';
import { MatDialogModule } from '@angular/material/dialog';
import { AppConfigService } from './core/configs/app.config';
// Highlight JS
import { HighlightModule, HIGHLIGHT_OPTIONS } from 'ngx-highlightjs';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import json from 'highlight.js/lib/languages/json';
import { MatIconModule } from '@angular/material/icon';
import { MatExpansionModule } from '@angular/material/expansion';
import { EnvServiceProvider } from './core/services/env.service.provider';

/**
 * Import specific languages to avoid importing everything
 * The following will lazy load highlight.js core script (~9.6KB) + the selected languages bundle (each lang. ~1kb)
 */
export function getHighlightLanguages() {
    return [
        { name: 'json', func: json }
    ];
}

// We need a factory since localStorage is not available at AOT build time
export function storageFactory(): OAuthStorage {
    return localStorage;
}

export function loadConfigFactory(appConfig: AppConfigService) {
    return () => appConfig.loadConfig();
}

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        SplashScreenModule,
        TranslateModule.forRoot(),
        HttpClientModule,
        // HighlightModule.({
        //     languages: getHighlightLanguages
        // }),
        ClipboardModule,
        AppRoutingModule,
        InlineSVGModule.forRoot(),
        NgbModule,
        NgxUiLoaderModule,
        OAuthModule.forRoot(),
        MatDialogModule,
        MatIconModule,
        MatExpansionModule
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: loadConfigFactory,
            multi: true,
            deps: [AppConfigService],
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ApiInterceptor,
            multi: true
        },
        { provide: OAuthStorage, useFactory: storageFactory },
        EnvServiceProvider

    ],
    bootstrap: [AppComponent],
})
export class AppModule {
}
