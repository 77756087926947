<!-- begin::User Panel-->
<div id="kt_quick_user" class="offcanvas p-10" [ngClass]="extrasUserOffcanvasDirection">
  <ng-container *ngIf="authManager.user">
    <!--begin::Header-->
    <div class="offcanvas-header d-flex align-items-center justify-content-between pb-5">
      <h3 class="font-weight-bold m-0">
        User Profile
      </h3>
      <a href="#" class="btn btn-xs btn-icon btn-light btn-hover-primary" id="kt_quick_user_close">
        <i class="ki ki-close icon-xs text-muted"></i>
      </a>
    </div>
    <!--end::Header-->

    <!--begin::Content-->
    <div class="offcanvas-content pr-5 mr-n5">
      <!--begin::Header-->
      <div class="d-flex align-items-center mt-5">
        <div class="d-flex flex-column">
          <a class="font-weight-bold font-size-h5 text-dark-75 text-hover-primary">
            {{ authManager.user.FullName }}
          </a>
          <!--          <div class="text-muted mt-1">-->
          <!--            Application Developer-->
          <!--          </div>-->
          <div class="navi mt-2">
            <a class="navi-item">
              <span class="navi-link p-0 pb-2">
                <span class="navi-icon mr-1">
                  <span [inlineSVG]="
                      './assets/media/svg/icons/Communication/Mail-notification.svg'
                    " cacheSVG="true" class="svg-icon svg-icon-lg svg-icon-primary"></span>
                </span>
                <span class="navi-text text-muted text-hover-primary">{{
                  authManager.user.email
                  }}</span>
              </span>
            </a>

            <a class="btn btn-sm btn-light-primary font-weight-bolder py-2 px-5 cursor-pointer"
              (click)="authManager.logout()">
              Sign Out
            </a>
            <!-- <a class="btn btn-sm btn-light-primary font-weight-bolder my-2 py-2 px-5 cursor-pointer"
            (click)="changePasswor()">
              Change Password
            </a> -->
          </div>
        </div>
      </div>
      <!--end::Header-->

    </div>
    <!--end::Content-->
  </ng-container>
</div>
<!-- end::User Panel-->