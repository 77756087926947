<!--begin::Topbar-->
<ng-container>
    <!--begin::Search-->
    <div class="topbar-item symbol-light-success mr-auto">
        <span class="symbol-label font-size-h5 font-weight-bold">
          {{permissionsManager.activeUserCompany()}}
        </span>
    </div>
    <!--end::Search-->
</ng-container>



<ng-container class="ml-auto" *ngIf="extrasUserDisplay">
    <ng-container *ngIf="extrasUserLayout === 'offcanvas'">
        <ng-container *ngIf="authManager.user">
            <!--begin::User-->
            <div class="topbar-item">
                <div
                        class="btn btn-icon btn-icon-mobile w-auto btn-clean d-flex align-items-center btn-lg px-2"
                        id="kt_quick_user_toggle"
                >
          <span
                  class="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1"
          >Hi,</span
          >
                    <span
                            class="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3"
                    >{{ authManager.user.FullName }}</span
                    >
                    <span class="symbol symbol-lg-35 symbol-25 symbol-light-success">
            <span class="symbol-label font-size-h5 font-weight-bold">{{
                authManager.user?.FullName | firstLetter
                }}</span>
          </span>
                </div>
            </div>
            <!--end::User-->
        </ng-container>
    </ng-container>

    <ng-container *ngIf="extrasUserLayout === 'dropdown'">
        <!--begin::User-->
        <ng-container *ngIf="authManager.user">
            <div class="dropdown" ngbDropdown placement="bottom-right">
                <div
                        class="topbar-item"
                        data-toggle="dropdown"
                        data-offset="0px,0px"
                        ngbDropdownToggle
                >
                    <div
                            class="btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2"
                    >
            <span
                    class="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1"
            >Hi,</span
            >
                        <span
                                class="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3"
                        >{{ authManager.user.FullName }}</span
                        >
                        <span class="symbol symbol-35 symbol-light-success">
              <span class="symbol-label font-size-h5 font-weight-bold">{{
                  authManager.user?.FullName | firstLetter
                  }}</span>
            </span>
                    </div>
                </div>
                <!--end::Toggle-->
                <!--begin::Dropdown-->
                <div
                        ngbDropdownMenu
                        class="dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-lg p-0"
                >
                    <app-user-dropdown-inner></app-user-dropdown-inner>
                </div>
            </div>
            <!--end::Dropdown-->
        </ng-container>
        <!--end::User-->
    </ng-container>
</ng-container>

<!--end::Topbar-->
