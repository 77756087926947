import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfig, ServerConfigs } from './app-config.model';
import { AuthConfig, OAuthService } from 'angular-oauth2-oidc';
import { EnvService } from '../services/env.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AppConfigService {
  private config: ServerConfigs;

  constructor(private http: HttpClient,
    private oauthService: OAuthService,
    private env: EnvService) {
  }


  public loadConfig() {
    console.log('env', this.env);
    const d = environment[this.env.activeServer];
    console.log('ENVVVV >> ', d);
    this.config = environment[this.env.activeServer];
    this.setAuthConfigs();
  }

  public getConfig(): ServerConfigs {
    this.config = environment[this.env.activeServer];
    return this.config;
  }

  public setAuthConfigs() {
    const authConfig: AuthConfig = {
      issuer: this.config.identityUrl,
      clientId: this.config.clientId,
      dummyClientSecret: this.config.dummyClientSecret,
      responseType: 'code',
      redirectUri: window.location.origin + '/auth/callback',
      postLogoutRedirectUri: window.location.origin + '/auth/logout',
      scope: 'openid profile MotorInsurance Identity BusinessLog offline_access Payment',
      useSilentRefresh: false, // Needed for Code Flow to suggest using iframe-based refreshes
      clearHashAfterLogin: false,
      nonceStateSeparator: 'semicolon',
      requireHttps: false,
      showDebugInformation: true,
      customQueryParams: {
        ui_locales: localStorage.getItem('LANG'),
      },
    };

    console.log(authConfig);

    this.oauthService.configure(authConfig);
  }
}
