import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';
import { LayoutComponent } from './pages/_layout/layout.component';
import { ContentGuard } from './core/guards/content.guard';

export const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () =>
      import('./pages/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: '',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'insurance-companies',
        // canActivate: [ContentGuard],
        // data: { permission: 'Admin' },
        loadChildren: () =>
          import('./pages/insurance-companies/insurance-companies.module').then(
            (m) => m.InsuranceCompaniesModule
          ),
      },
      {
        path: 'najm-elm',
        // canActivate: [ContentGuard],
        // data: { permission: 'EqelhaAdmin' },
        loadChildren: () =>
          import('./pages/najm-elm/najm-elm.module').then(
            (m) => m.NajmElmModule
          ),
      },
      {
        path: 'hyper-pay',
        // canActivate: [ContentGuard],
        // data: { permission: 'EqelhaAdmin' },
        loadChildren: () =>
          import('./pages/hyper-pay/hyper-pay.module').then(
            (m) => m.HyperPayModule
          ),
      },
      {
        path: 'esal',
        canActivate: [ContentGuard],
        data: { permission: 'EqelhaAdmin' },
        loadChildren: () =>
          import('./pages/esal/esal.module').then((m) => m.EsalModule),
      },
      {
        path: 'finance-report',
        canActivate: [ContentGuard],
        data: { permission: 'Admin' },
        loadChildren: () =>
          import('./pages/finance-reports/finance-reports.module').then(
            (m) => m.FinanceReportsModule
          ),
      },
      {
        path: 'failed-policies',
        canActivate: [ContentGuard],
        data: { permission: 'Admin' },
        loadChildren: () =>
          import('./pages/failed-policies/failed-policies.module').then(
            (m) => m.FailedPoliciesModule
          ),
      },
      {
        path: 'quotes-statistics',
        // canActivate: [ContentGuard],
        // data: { permission: 'Admin' },
        loadChildren: () =>
          import(
            './pages/statistics/quotes-statistics/quotes-statistics.module'
          ).then((m) => m.QuotesStatisticsModule),
      },
      {
        path: 'policies-statistics',
        canActivate: [ContentGuard],
        data: { permission: 'Admin' },
        loadChildren: () =>
          import(
            './pages/statistics/policies-statistics/policies-statistics.module'
          ).then((m) => m.PoliciesStatisticsModule),
      },
      {
        path: 'accounts-statistics',
        canActivate: [ContentGuard],
        data: { permission: 'Admin' },
        loadChildren: () =>
          import(
            './pages/statistics/accounts-statistics/accounts-statistics.module'
          ).then((m) => m.AccountsStatisticsModule),
      },
      {
        path: 'najm-statistics',
        canActivate: [ContentGuard],
        data: { permission: 'Admin' },
        loadChildren: () =>
          import(
            './pages/statistics/najm-statistics/najm-statistics.module'
          ).then((m) => m.NajmStatisticsModule),
      },
      {
        path: 'settings',
        canActivate: [ContentGuard],
        data: { permission: 'TechAdmin' },
        loadChildren: () =>
          import('./pages/discount-settings/discount.settings.module').then(
            (m) => m.DiscountSettingsModule
          ),
      },
      {
        path: 'company-settings',
        canActivate: [ContentGuard],
        data: { permission: 'TechAdmin' },
        loadChildren: () =>
          import('./pages/company-setting/company-setting.module').then(
            (m) => m.CompanySettingModule
          ),
      },
      {
        path: 'delete-nin',
        canActivate: [ContentGuard],
        data: { permission: 'EqlhaAdminOnly' },
        loadChildren: () =>
          import('./pages/deletenin/deletenin.module').then(
            (m) => m.DeleteninModule
          ),
      },
      {
        path: '',
        redirectTo: 'insurance-companies',
        pathMatch: 'full',
      },
    ],
  },
  { path: '**', redirectTo: 'errors/404', pathMatch: 'full' },
];

const config: ExtraOptions = {
  useHash: false,
  initialNavigation: !!localStorage.getItem('access_token'),
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
