import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpErrorResponse,
    HttpResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Injectable({
    providedIn: 'root'
})

export class ApiInterceptor implements HttpInterceptor {
    constructor(
        private authService: AuthService,
        private loaderService: NgxUiLoaderService) {
    }

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        if (!req.headers.get('skip-loader')) {
            this.loaderService.start(req.url);
        }
        const token = this.authService.accessToken;

        const clonedReq = req.clone({
            setHeaders: {
                Authorization: 'Bearer ' + token
            }
        });

        return next.handle(clonedReq).pipe(
            tap(evt => {
                if (evt instanceof HttpResponse) {
                    this.loaderService.stop(req.url);
                    return evt.body;
                }
            }), catchError((error: HttpErrorResponse) => {
                this.loaderService.stop(req.url);
               // console.log('stop2');
                return throwError(error);
            }),
            finalize(() => {
               // console.log('stop3');
               // this.loaderService.stop(req.url);
            })
        );
    }
}
