import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FirstLetterPipe} from './pipes/first-letter.pipe';
import {SafePipe} from './pipes/safe.pipe';
import {ReactiveFormsModule} from '@angular/forms';

@NgModule({
    declarations: [FirstLetterPipe, SafePipe],
    imports: [CommonModule, ReactiveFormsModule],
    exports: [ReactiveFormsModule, FirstLetterPipe, SafePipe]
})
export class CoreModule {
}
