export const DynamicAsideMenuConfig = {
  items: [
    {
      title: 'Insurance Companies Logs',
      root: true,
      iconClass: 'fas fa-car-crash',
      page: '/insurance-companies',
      permissions: ['Admin', 'TechAdmin'],
      bullet: 'dot',
    },
    {
      title: 'Najm & Elm Logs',
      root: true,
      iconClass: 'fas fa-address-card',
      page: '/najm-elm',
      permissions: ['Admin', 'TechAdmin'],
      bullet: 'dot',
    },
    {
      title: 'Delete NIN',
      root: true,
      iconClass: 'fas fa-trash',
      page: '/delete-nin',
      permissions: ['Admin', 'TechAdmin'],
      bullet: 'dot',
    },
    {
      title: 'Hyper pay Logs',
      root: true,
      iconClass: 'fas fa-dollar-sign',
      page: '/hyper-pay/logs',
      permissions: ['Admin', 'TechAdmin'],
      bullet: 'dot',
    },
    {
      title: 'Reset Failure Purchase',
      root: true,
      iconClass: 'fas fa-recycle',
      page: '/hyper-pay/reset-failure-purchase',
      permissions: ['Admin', 'TechAdmin'],
      bullet: 'dot',
    },
    {
      title: 'Esal Logs',
      root: true,
      iconClass: 'fas fa-archive',
      page: '/esal/logs',
      permissions: ['Admin', 'TechAdmin'],
      bullet: 'dot',
    },
    {
      title: 'Finance Reports',
      root: true,
      iconClass: 'fas fa-wallet',
      page: '/finance-report',
      permissions: ['Admin', 'TechAdmin'],
      bullet: 'dot',
    },
    {
      title: 'Add New Discount',
      root: true,
      iconClass: 'fa fa-tag fa-stack',
      page: 'settings/add-new-discount',
      permissions: ['TechAdmin'],
      bullet: 'dot',
    },
    {
      title: 'Update Discount',
      root: true,
      iconClass: 'fa fa-percent',
      page: 'settings/update-discount',
      permissions: ['TechAdmin'],
      bullet: 'dot',
    },
    {
      title: 'Update Company Status',
      root: true,
      iconClass: 'fas fa-edit',
      page: 'company-settings/update-company-status',
      permissions: ['TechAdmin'],
      bullet: 'dot',
    },
    {
      title: 'Retry payout hyperpay',
      root: true,
      iconClass: 'fa fa-share',
      page: '/hyper-pay/retry-payout',
      permissions: ['TechAdmin'],
      bullet: 'dot',
    },
    {
      title: 'Failed Policies',
      root: true,
      iconClass: 'fa fa-wrench',
      page: '/failed-policies',
      permissions: ['Admin', 'TechAdmin'],
      bullet: 'dot',
    },
    {
      title: 'Quotes Statistics',
      root: true,
      iconClass: 'fa fa-chart-line',
      page: '/quotes-statistics',
      permissions: ['TechAdmin'],
      bullet: 'dot',
    },
    {
      title: 'Policies Statistics',
      root: true,
      iconClass: 'fa fa-chart-line',
      page: '/policies-statistics',
      permissions: ['TechAdmin'],
      bullet: 'dot',
    },
    {
      title: 'Accounts Statistics',
      root: true,
      iconClass: 'fa fa-chart-line',
      page: '/accounts-statistics',
      permissions: ['TechAdmin'],
      bullet: 'dot',
    },
    {
      title: 'Najm Statistics',
      root: true,
      iconClass: 'fa fa-chart-line',
      page: '/najm-statistics',
      permissions: ['TechAdmin'],
      bullet: 'dot',
    },
  ],
};