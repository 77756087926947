// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  appVersion: 'v711demo1',
  USERDATA_KEY: 'authf649fc9a5f55',
  isMockEnabled: false,
  apiUrl: 'api',


  activeServer: "dev",
  local: {
    production: false,
    serverUrl: "https://localhost:62710/api/",
    insurerQuoteServiceUrl: "https://localhost:44319/api/motor-insurance/",
    baseUrl: "https://localhost:44319/",
    identityUrl: "https://localhost:44331",
    dummyClientSecret: "km_JeQ8W50",
    clientId: "InsuNow.Admin"
  },
  dev: {
    production: true,
    serverUrl: "https://api-dev.insunow.sa/apigateway/api/BusinessLog/",
    insurerQuoteServiceUrl: "https://api-dev.insunow.sa/apigateway/api/motor-insurance/",
    baseUrl: "https://api-dev.insunow.sa/apigateway/",
    identityUrl: "https://api-dev.insunow.sa/identity",
    dummyClientSecret: "km_JeQ8W50",
    clientId: "InsuNow.Admin"
  },
  qc: {
    production: true,
    serverUrl: "https://api-qc.insunow.sa/apigateway/api/BusinessLog/",
    insurerQuoteServiceUrl: "https://api-qc.insunow.sa/apigateway/api/motor-insurance/",
    baseUrl: "https://api-qc.insunow.sa/apigateway/",
    identityUrl: "https://api-qc.insunow.sa/identity",
    dummyClientSecret: "km_JeQ8W50",
    clientId: "InsuNow.Admin"
  },
  uat: {
    production: true,
    serverUrl: "https://api.bawbty.com/api/BusinessLog/",
    insurerQuoteServiceUrl: "https://api.bawbty.com/",
    dummyClientSecret: "km_JeQ8W50",
    clientId: "InsuNow.Admin"
  },
  prod: {
    production: true,
    serverUrl: "https://api.eqelha.com/api/BusinessLog/",
    insurerQuoteServiceUrl: "https://api.eqelha.com/",
    dummyClientSecret: "km_JeQ8W50",
    clientId: "InsuNow.Admin"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
