import { Injectable } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { isArray } from 'util';

@Injectable({
    providedIn: 'root'
})
export class PermissionsManager {

    constructor(private authService: AuthService) {
    }

    /**
     * Gets user Permissions from LocalStorage
     */
    public getUserRoles() {
        return this.authService.claims.role;
    }

    public isPermissionGranted(permissionName) {
        const roles = this.getUserRoles();
        if (roles) {
            // 'TechAdmin' role have permession on all pages
            if (roles === 'TechAdmin') {
                return true;
            }
            // pages where only eqelha admin and tech admin can access and the compnies admin can not
            if (permissionName === 'EqlhaAdminOnly') {
                if (roles === 'Admin') {
                    return true;
                }
                else{
                    return false;
                }
            }
            // 'Admin' role have permession on 'EqelhaAdmin' pages
            if (permissionName === 'EqelhaAdmin' || permissionName === 'Admin') {
                if (roles === 'Admin') {
                    return true;
                } else {
                    if (roles.indexOf('Admin') >= 0) { return true; }
                    else { return false; }
                }
            } else {
                // roles here should be array ['Admin','companyPermessionName']
                if (roles.indexOf(permissionName) >= 0) {
                    return true;
                } else {
                    return false;
                }
            }
        }
        else {
            return false;
        }

    }

    public activeUserCompany() {
        const userRoles: string[] = this.getUserRoles();
        if (userRoles?.indexOf('Admin') >= 0) {
            if (Array.isArray(userRoles)) { userRoles.splice(userRoles.indexOf('Admin'), 1); }
        }
        return Array.isArray(userRoles) ? userRoles[0] : null;
    }
}
